// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const AccountIcon = (props: PropsType): React.Node => {
  return (
    <svg
      height={props.size || 30}
      width={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5147 26.7891L3.48682 26.824H3.48364C3.35002 26.824 3.25489 26.7859 3.20081 26.7545C4.10722 21.8985 9.03859 18.3795 14.988 18.3697H15.0103C20.9502 18.3697 25.8816 21.8696 26.7982 26.7192C26.7441 26.751 26.6515 26.7891 26.5147 26.7891M9.52844 8.6389C9.52844 5.62778 11.9814 3.17595 15.001 3.17595C18.0168 3.17595 20.4729 5.62778 20.4729 8.6389C20.4729 11.6526 18.0168 14.1047 15.001 14.1047C11.9814 14.1047 9.52844 11.6526 9.52844 8.6389M29.957 26.3127C29.0853 21.2852 25.0737 17.3375 19.7733 15.8448C22.1114 14.2949 23.6544 11.6433 23.6544 8.63889C23.6544 3.87783 19.7733 0 15.001 0C10.2287 0 6.34694 3.87783 6.34694 8.63889C6.34694 11.6433 7.89348 14.2981 10.2316 15.8483C4.91525 17.3534 0.896986 21.3173 0.0411551 26.3575C-0.105195 27.2083 0.142964 28.0849 0.71882 28.7677C1.38376 29.549 2.3923 30 3.48357 30H3.48993L26.5178 29.9651C27.6154 29.9651 28.624 29.5109 29.2857 28.7233C29.8616 28.0436 30.1066 27.1639 29.957 26.3127"
        fill={props.color || colors.white}
      />
    </svg>
  )
}

export default AccountIcon
