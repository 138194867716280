// @flow
import * as React from 'react'
import { CTAPrimaryLink, CTAPrimary, NavButton, UnderlineOnFocusVisible } from './shared/buttons'
import styled, { css } from 'styled-components'
import {
  Container,
  Row,
  config,
  Visible,
  ThemedStyledProps
} from 'react-awesome-styled-grid'
import type { AppStateType } from '../reducers/appstate'
import type { Dispatch } from 'redux'
import { useRouter, withRouter } from 'next/router'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  openSubscribe,
  openOurStory,
  openBlog,
  openGift,
  openHome,
  openListen
} from '../actions/navigationActions'
import { signOut } from '../actions/userActions'
import { DipseaLogo } from './shared/icons'
import { colors } from '@components/shared'
import { togglePromoModal } from '../actions/promoActions'
import Link from 'next/link'
import AccountIcon from '@components/shared/icons/AccountIcon'
import SearchIcon from '@components/shared/icons/SearchIcon'
import MyStoriesIcon from '@components/shared/icons/MyStoriesIcon'
import DiscoverIcon from '@components/shared/icons/DiscoverIcon'
import { toggleSubscriptionModalAction } from '../actions/subscriptionActions'
import { useSearchParams } from 'next/navigation'

type PropsType = {
  backgroundColor: string, // on browsers that don't support blur, we need a background color,
  isSubscribed: boolean,
  signedIn: boolean,
  isAssetLoaded: boolean,
  isMarketingNav?: boolean,
  isDipseaLogoOnly?: boolean,
  isPromoModal: boolean,
  isStatic: boolean,
  isDiscover: boolean,
  isBlog?: boolean
}

type DispatchPropsType = {
  openSubscribe: () => void,
  openOurStory: () => void,
  openBlog: () => void,
  openGift: () => void,
  openHome: () => void,
  signOut: () => void,
  listen: () => void,
  togglePromoModal: () => void
}

export function TopNav (props: PropsType & DispatchPropsType): React.Node {
  const {
    isMarketingNav,
    isPromoModal,
    openSubscribe,
    togglePromoModal,
    isDipseaLogoOnly,
    isStatic,
    isDiscover
  } = props
  const navRef = React.useRef(null)
  const userSignedIn = useSelector((state: AppStateType): boolean => {
    return !!state?.user?.id
  })
  const router = useRouter()
  const searchParams = useSearchParams()
  const subscriptionCta = useSelector<string>((state: AppStateType): string => state.subscriptionCTA)
  const [loadAnimation, setLoadAnimation] = React.useState<boolean>(!!isStatic)
  const [loadedAnimation, setLoadedAnimation] = React.useState<boolean>(!!isStatic)
  const [isSimpleNav, setIsSimpleNav] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const openMyStories = (): void => {
    if (userSignedIn) {
      router.push('/my-stories/favorites')
    } else {
      dispatch(toggleSubscriptionModalAction())
    }
  }

  React.useEffect((): (() => void) => {
    if (!loadAnimation && props.isAssetLoaded) {
      setTimeout((): void => setLoadAnimation(true), 500)
      setTimeout((): void => setLoadedAnimation(true), 1500)
    }
    return (): void => {
      setLoadAnimation(!!isStatic)
    }
  }, [props.isAssetLoaded])

  React.useEffect((): void => {
    const n = searchParams?.get('n')
    if (!!n && n === 's') {
      setIsSimpleNav(true)
    }
  }, [searchParams])

  if (isDipseaLogoOnly) {
    return (
      <Nav ref={navRef} load={loadAnimation}>
        <NavContainer
          load={loadAnimation}
          backupBackgroundColor={props.backgroundColor}
          loadedAnimation={loadedAnimation}
        >
          <Container>
            <Row>
              <SimpleNavContainer>
                <HomeButton
                  onClick={(): void => props.openHome()}
                  aria-label='Dipsea, opens homepage'
                >
                  <Visible xs={true} sm={true} md={false} lg={false} xl={false}>
                    <DipseaLogo size={114} />
                  </Visible>
                  <Visible xs={false} sm={false} md={true} lg={true} xl={true}>
                    <DipseaLogo />
                  </Visible>
                </HomeButton>
              </SimpleNavContainer>
            </Row>
          </Container>
        </NavContainer>
      </Nav>
    )
  }

  const handleClickTryForFree = (): void => {
    if (isPromoModal && togglePromoModal) {
      togglePromoModal()
    } else {
      openSubscribe()
    }
  }

  if (isMarketingNav || isSimpleNav) {
    return (
      <Nav ref={navRef} load={loadAnimation} className='marketing-nav-bar'>
        <NavContainer
          load={loadAnimation}
          backupBackgroundColor={props.backgroundColor}
          loadedAnimation={loadedAnimation}
        >
          <Container>
            <HeaderContainer>
              <Visible xs={false} sm={false} md={true} lg={true} xl={true}>
                <HeaderLeft></HeaderLeft>
              </Visible>
              <HomeButton
                onClick={(): void => props.openHome()}
                aria-label='Dipsea, opens homepage'
              >
                <Visible xs={true} sm={true} md={false} lg={false} xl={false}>
                  <DipseaLogo size={114} />
                </Visible>
                <Visible xs={false} sm={false} md={true} lg={true} xl={true}>
                  <DipseaLogo />
                </Visible>
              </HomeButton>
              <HeaderRight>
                {isSimpleNav && (
                  <NavLink href={'/account'} passHref={true} styles={{ marginRight: 0 }}><AccountIcon size={17} /></NavLink>
                )}
                {!props.isSubscribed && !isSimpleNav && (
                  <>
                    <Visible
                      xs={false}
                      sm={false}
                      md={false}
                      lg={true}
                      xl={true}
                    >
                      <CTAPrimary onClick={handleClickTryForFree}>
                        {subscriptionCta}
                      </CTAPrimary>
                    </Visible>
                    <Visible
                      xs={true}
                      sm={true}
                      md={true}
                      lg={false}
                      xl={false}
                    >
                      <CTAPrimary onClick={handleClickTryForFree}>
                        {subscriptionCta}
                      </CTAPrimary>
                    </Visible>
                  </>
                )}
              </HeaderRight>
            </HeaderContainer>
          </Container>
        </NavContainer>
      </Nav>
    )
  }

  if (isDiscover) {
    let tabSelected = 'discover'
    switch (router.pathname) {
      case '/search':
        tabSelected = 'search'
        break
      case '/my-stories/favorites':
        tabSelected = 'my-stories'
        break
      case '/my-stories/listen-history':
        tabSelected = 'my-stories'
        break
    }
    return (
      <Visible xs={false} sm={false} md={true} lg={true} xl={true}>
        <Nav ref={navRef} load={loadAnimation}>
          <NavContainer
            load={loadAnimation}
            backupBackgroundColor={props.backgroundColor}
            loadedAnimation={loadedAnimation}
          >
            <Container>
              <Row>
                <HeaderContainer>
                  <DiscoverLeft>
                    <HomeButton
                      onClick={(): void => props.openHome()}
                      aria-label='Dipsea, opens homepage'
                    >
                      <DipseaLogo />
                    </HomeButton>
                  </DiscoverLeft>
                  <HeaderRight>
                    <DiscoverNavLink
                      href={'/discover'}
                      passHref={true}
                      $selected={tabSelected === 'discover'}
                    >
                      <DiscoverIcon size={17} color={tabSelected === 'discover' ? colors.white : colors.white50} /><span>Browse</span>
                    </DiscoverNavLink>
                    <DiscoverNavLink
                      href={'/search'}
                      passHref={true}
                      $selected={tabSelected === 'search'}
                    >
                      <SearchIcon size={17} color={tabSelected === 'search' ? colors.white : colors.white50} /><span>Search</span>
                    </DiscoverNavLink>
                    <DiscoverNavButton
                      onClick={openMyStories}
                      $selected={tabSelected === 'my-stories'}
                      >
                      <MyStoriesIcon size={17} color={tabSelected === 'my-stories' ? colors.white : colors.white50} /><span>My Library</span>
                    </DiscoverNavButton>
                    <DiscoverNavLink
                      href={'/account'}
                      passHref={true}
                    >
                      <AccountIcon size={17} color={colors.white50} /><span>Account</span>
                    </DiscoverNavLink>
                  </HeaderRight>
                </HeaderContainer>
              </Row>
            </Container>
          </NavContainer>
        </Nav>
      </Visible>
    )
  }

  return (
    <Visible xs={false} sm={false} md={false} lg={true} xl={true}>
      <Nav ref={navRef} load={loadAnimation}>
        <NavContainer
          load={loadAnimation}
          backupBackgroundColor={props.backgroundColor}
          loadedAnimation={loadedAnimation}
        >
          <Container>
            <Row>
              <HeaderContainer>
                <HeaderLeft>
                  <NavLink className='subscribe-cta' href={'/subscribe?f=2'} passHref={true} alt='Start a 30 day free trial'>
                    {subscriptionCta}
                  </NavLink>
                  <NavLink href={'/about'} passHref={true}>
                    About Us
                  </NavLink>
                  <NavLink href={'/blog'} passHref={true}>
                    Blog
                  </NavLink>
                </HeaderLeft>

                <HomeButton
                  onClick={(): void => props.openHome()}
                  aria-label='Dipsea, opens homepage'
                >
                  <DipseaLogo />
                </HomeButton>

                <HeaderRight>
                  {props.isBlog
                    ? <CTAPrimaryLink href={'/subscribe?f=2'} passHref={true}>
                      {subscriptionCta}
                      </CTAPrimaryLink>
                    : <CTAPrimaryLink href={'/discover'} passHref={true}>
                        Browse
                      </CTAPrimaryLink>}
                  <IconContainer>
                    <NavLink href={'/account'} passHref={true}><AccountIcon size={17} /></NavLink>
                  </IconContainer>
                </HeaderRight>
              </HeaderContainer>
            </Row>
          </Container>
        </NavContainer>
      </Nav>
    </Visible>
  )
}

const IconContainer = styled.div`
    margin: 0px 30px;
`

const SimpleNavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  ${(props: ThemedStyledProps): string => config(props).media.md`
    margin: 0px;
  `}
  ${(props: ThemedStyledProps): string => config(props).media.xl`
    margin: 0 20px;
  `}
`
const NavLink = styled(Link)`
  background: none;
  color: ${colors.sand};
  cursor: pointer;
  font-family: 'Good Sans Medium';
  font-size: 12px;
  line-height: 18px;
  height: 33px;
  outline: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  ${(props: ThemedStyledProps): string => config(props).media.md`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: ${colors.white};
  `}
  ${(props: ThemedStyledProps): string => config(props).media.xl`
    font-size: 12px;
  `}
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 25px;

  :hover {
    opacity: 0.8;
    transition: all 0.3s;
  }
  ${UnderlineOnFocusVisible};
  ${(props: any): ?string => props.styles};
`
const Nav = styled.nav`
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 10;

  height: 60px;
  ${(props: ThemedStyledProps): string => config(props).media.md`
    height: 75px;
  `}
`
const NavContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  height: 60px;
  ${(props: ThemedStyledProps): string => config(props).media.md`
    height: 75px;
  `}

  z-index: 1;
  ${(props: any): string =>
    props.loadedAnimation ? 'position: relative;' : 'position: absolute;'};
  ${(props: any): string => (props.load ? 'top: 0px' : 'top: -75px')};
  opacity: ${(props: any): number => (props.load ? 1 : 0)};
  transition: opacity ease-in 1.5s, top 1s linear;
  background: ${(props: { backupBackgroundColor: ?string }): string =>
    props.backupBackgroundColor
      ? props.backupBackgroundColor
      : colors.midnightBlue90};

  @supports (backdrop-filter: none) {
    background: rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(80px);
  }
`

const HomeButton = styled.button`
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;
  margin: 10px 0 0 0;
  ${UnderlineOnFocusVisible}
`

const DiscoverLeft = styled.div`
  ${(props: any): string => config(props).media.xs`
  `}
  ${(props: any): string => config(props).media.sm`
  `}
  ${(props: any): string => config(props).media.md`
  margin-left: 20px;
  `}
  ${(props: any): string => config(props).media.lg`
  `}
  ${(props: any): string => config(props).media.xl`
  `}
`
const HeaderLeft = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 424px;
  width: 100%;
  button {
      margin-right: 25px;
  }
  ${(props: any): string => config(props).media.md`
    justify-content: flex-start;
    max-width: none;
  `}
  ${(props: any): string => config(props).media.lg`
    button {
      margin-right: 48px;
    }
  `}
`
const navStyles = css`
  span {
    margin-left: 8px;
  }
  ${(props: any): string => props.$selected ? `color: ${colors.white}` : `color: ${colors.white50}`};
  ${(props: any): string => config(props).media.md`
    margin-right: 32px
  `}
  ${(props: any): string => config(props).media.lg`
    margin-right: 48px
  `}
  :hover {
    opacity: ${(props: any): number => props.$selected ? 1 : 0.6};
    transition: all 0.3s;
  }
`
const DiscoverNavLink = styled(NavLink)`
  ${navStyles}
`
const DiscoverNavButton = styled(NavButton)`
 ${navStyles}
  display: flex;
  flex-direction: row;
  align-items: center;
`
const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

type OwnPropsType = {
  isMarketingNav?: boolean,
  isDipseaLogoOnly?: boolean
}
const mapStateToProps = (state: AppStateType, ownProps: OwnPropsType): any => {
  return {
    isSubscribed: state.user.isSubscribed,
    signedIn: state.user.id,
    isMarketingNav: ownProps.isMarketingNav,
    isDipseaLogoOnly: ownProps.isDipseaLogoOnly
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  return ({
    openSubscribe: (): void => dispatch(openSubscribe()),
    openOurStory: (): void => dispatch(openOurStory()),
    openBlog: (): void => dispatch(openBlog()),
    openGift: (): void => dispatch(openGift()),
    openHome: (): void => dispatch(openHome()),
    signOut: (): void => dispatch(signOut()),
    listen: (): void => dispatch(openListen()),
    togglePromoModal: (): void => dispatch(togglePromoModal())
  }: DispatchPropsType)
}

export default withRouter(
  connect < PropsType & DispatchPropsType, *, _, _, _, _ >(
    mapStateToProps,
    mapDispatchToProps
  )(TopNav)
)
