// @flow
import * as React from 'react'
import Head from 'next/head'
import Script from 'next/script'
export type PropsType = {
  title: string,
  description?: string | null,
  image?: string | null,
  includeStripe?: boolean,
  canonicalUrl?: string
}
function HtmlHead (props: PropsType): React.Node {
  const description = props.description || 'Spicy audiobooks and more, featuring your favorite tropes and irresistible characters. Crafted by our team of professional writers and top-tier narrators.'
  const title = props.title ? `${props.title}` : `Dipsea Stories`
  let image = props.image ? props.image : 'https://www.dipseastories.com/images/share-image-dusk.jpg'
  // check to see if its a contentful url, and ensure it has https on it
  if (image.match(/^\/\/images/i)) {
    image = image.replace(/^\/\/images/i, 'https://images')
  }
  return (
    <>
      <Head>
        <title>{props.title}</title>
        <meta key="og-url" property="og:url" content="https://www.dipseastories.com/"/>
        <meta key="og-title" property="og:title" content={title} />
        <meta key="description" name="description" content={description} />
        <meta key="og-description" property="og:description" content={description} />
        <meta key="og-image" property="og:image" content={image}/>
        <meta key="og-image:type" property="og:image:type" content="image/jpeg" />
        <meta key="og-image:width" property="og:image:width" content="1608" />
        <meta key="og-image:height" property="og:image:height" content="969" />
        <meta key="og-image:alt" property="og:image:alt" content="Dipsea" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@DipseaStories" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {props.canonicalUrl && <link rel="canonical" href={props.canonicalUrl} key="canonical"/>}
      </Head>
      {props.includeStripe && <Script src="https://js.stripe.com/v3/" />}
    </>
  )
}

export default HtmlHead
